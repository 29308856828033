
        import * as module0 from './controllers/accordion_controller.js';import * as module1 from './controllers/audio_player_control_controller.js';import * as module2 from './controllers/audio_player_controller.js';import * as module3 from './controllers/books_controller.js';import * as module4 from './controllers/carousel_controller.js';import * as module5 from './controllers/dropdown_controller.js';import * as module6 from './controllers/notes_controller.js';import * as module7 from './controllers/select_controller.js';import * as module8 from './controllers/songs_controller.js'
        const modules = {
            "./controllers/accordion_controller.js": module0,
            "./controllers/audio_player_control_controller.js": module1,
            "./controllers/audio_player_controller.js": module2,
            "./controllers/books_controller.js": module3,
            "./controllers/carousel_controller.js": module4,
            "./controllers/dropdown_controller.js": module5,
            "./controllers/notes_controller.js": module6,
            "./controllers/select_controller.js": module7,
            "./controllers/songs_controller.js": module8,
        };
        export default modules;
      